import React, {useEffect, useRef, useState} from "react";
import {FitAddon} from '@xterm/addon-fit';
import {ITerminalAddon, Terminal} from "@xterm/xterm";
import DreamscapeApiFactory from "../dreamscape-api/DreamscapeApiFactory";
import {createRoot} from "react-dom/client";
import {DefaultApi, Session, SessionState, StartSessionRequest} from "../dreamscape-api/generated-src";
import {
  Box,
  Button,
  FileUpload,
  Flashbar,
  FormField,
  Link,
  Modal,
  NonCancelableCustomEvent,
  SpaceBetween,
  TopNavigation
} from "@amzn/awsui-components-react";
import {getAppSetting} from "../config/AppSettings";
import {
  DreamscapeMessage,
  PTYSessionResize,
  S3Download,
  WebSocketCommand
} from "@amzn/coral_com-amazonaws-console-dreamscape-model";
import {getMidwayJwtToken} from "../auth/MidwayJwtToken";
import {FlashbarProps} from "@amzn/awsui-components-react/polaris/flashbar/interfaces";
import {v4} from "uuid";
import {hideLoading, showLoading} from "./Loading";
import {FileUploadProps} from "@amzn/awsui-components-react/polaris/file-upload/interfaces";
import {TopNavigationProps} from "@amzn/awsui-components-react/polaris/top-navigation/interfaces";

const DataLenLimit = 16 * 1024;

export default function Connect({sessionId}) {
  const [sessionName, setSessionName] = useState<string>("");
  const [sessionState, setSessionState] = useState<SessionState>(SessionState.New);
  const terminalRef = useRef<HTMLDivElement>(null)
  const [terminalInstance, setTerminalInstance] = useState<Terminal | null>(null)
  const [showFileUpload, setShowFileUpload] = useState<boolean>(false);
  const [fileUploadList, setFileUploadList] = useState<File[]>([]);
  const [uploadFlash, setUploadFlash] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [flash, setFlash] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [showCloseSessionConfirm, setShowCloseSessionConfirm] = useState<boolean>(false);
  const [sessionDrainMessageId, setSessionDrainMessageId] = useState<string>(v4);

  const sessionStateBullet = (sessionState: SessionState): string => {
    const sessionStateMapping = {
      [SessionState.New]: "🔷",
      [SessionState.Grace]: "🟨",
      [SessionState.Active]: "🟢",
      [SessionState.Failed]: "❌",
      [SessionState.Closed]: "🔻"
    };
    return sessionStateMapping[sessionState] || "⚫";
  }

  class WebSocketAddon implements ITerminalAddon {
    private _terminal: Terminal | undefined;
    private _messageId: string | undefined; // stores last sent messageId
    private _inputBuffer: string; // buffers input until last messageId is ack'd
    private _webSocket: WebSocket | undefined;
    private _sessionState: SessionState;
    private _replay: boolean;
    private _activated: boolean;
    private _connectInterval: number | undefined;
    readonly _sessionId: string;
    readonly _webSocketBaseUrl: string;
    readonly _dreamscapeApi: DefaultApi;

    public constructor(sessionId: string) {
      this._activated = false;
      this._sessionId = sessionId;
      this._sessionState = SessionState.New;
      this._replay = false;
      this._inputBuffer = "";
      this._webSocketBaseUrl = `wss://${getAppSetting('wsUrl')}`
      this._dreamscapeApi = DreamscapeApiFactory();
    }

    public activate(terminal: Terminal): void {
      const me = this;
      if (me._activated) {
        return;
      }
      me._activated = true;
      me._terminal = terminal;
      if (me._webSocket) {
        me._webSocket.close();
      }
      me._webSocket = undefined;
      me.scheduleConnect();
      me.schedulePing();
    }

    showError(message: FlashbarProps.MessageDefinition, clearPrevious: boolean = false) {
      const newFlash: FlashbarProps.MessageDefinition[] = [];
      if (!message.id) {
        message.id = v4();
      }
      if (!clearPrevious) {
        flash.forEach((item) => {
          if (item.id === message.id) {
            return;
          }
          newFlash.push(item);
        });
      }
      if (!message.type) {
        message.type = "error";
      }
      if (message.dismissible === undefined) {
        message.dismissible = true;
      }
      if (message.dismissible) {
        message.onDismiss = () => {
          const newFlash: FlashbarProps.MessageDefinition[] = [];
          flash.forEach(item => {
            if (item.id === message.id) {
              return;
            }
            newFlash.push(item);
          });
          setFlash(newFlash);
        };
      }
      newFlash.push(message);
      setFlash(newFlash);
    }

    dispose(): void {
      const me = this;
      if (me._connectInterval) {
        clearInterval(me._connectInterval);
      }
    }

    setTitles(session: Session) {
      const me = this;
      const sessionName = session.sessionName || session.sessionId || me._sessionId;
      const sessionState = me._sessionState;
      setSessionName(sessionName);
      setSessionState(sessionState as SessionState);
      document.title = `DS - ${sessionName} ${sessionStateBullet(sessionState)}`;
    }

    isOpen = () => {
      const me = this;
      return me._webSocket !== undefined && me._webSocket.readyState === WebSocket.OPEN;
    }

    isSessionActive = () => {
      const me = this;
      return me._sessionState === SessionState.Active;
    }

    isClosable = () => {
      const me = this;
      return me._sessionState !== SessionState.Closed && me._sessionState !== SessionState.Failed;
    }

    scheduleConnect = () => {
      const me = this;
      me._replay = true;
      setTimeout(() => {
        me.connect();
      }, 1000);
    }

    schedulePing = () => {
      const me = this;
      me._connectInterval = setInterval(() => {
        if (!me.sendPing()) {
          clearInterval(me._connectInterval);
        }
      }, 10000);
    }

    sendPing = (): boolean => {
      const me = this;
      // sending ping only if already connected
      if (!me.isOpen()) {
        return true;
      }
      const pingMessage = DreamscapeMessage.fromJson(
        {
          action: WebSocketCommand.PING,
          sessionId: me._sessionId,
          data: JSON.stringify({
            sessionId: me._sessionId
          })
        }
      )
      me._webSocket?.send(JSON.stringify(DreamscapeMessage.toJson(pingMessage)));
      return true;
    }

    sendPong = () => {
      const me = this;
      if (!me.isOpen()) {
        return;
      }
      me._webSocket?.send(JSON.stringify({
        action: WebSocketCommand.PONG,
        sessionId: me._sessionId
      }));
    }

    sendResize = () => {
      const me = this;
      if (!me.isOpen()) {
        return
      }
      const ptySessionResize = PTYSessionResize.clone({
        sessionId: me._sessionId,
        cols: me._terminal?.cols,
        rows: me._terminal?.rows
      })
      const resizeMessage = DreamscapeMessage.fromJson({
        action: WebSocketCommand.RESIZE,
        sessionId: me._sessionId,
        data: JSON.stringify(PTYSessionResize.toJson(ptySessionResize))
      });
      me._webSocket?.send(JSON.stringify(DreamscapeMessage.toJson(resizeMessage)));
    }

    sendNotify = (bucket: String, key: String): void => {
      const me = this;
      if (!me.isOpen()) {
        return
      }
      const s3Download: S3Download = S3Download.clone({
        bucket: bucket.toString(),
        key: key.toString()
      })
      const notificationMessage = DreamscapeMessage.fromJson({
        action: WebSocketCommand.FILE_UPLOAD_NOTIFY,
        sessionId: me._sessionId,
        data: JSON.stringify(S3Download.toJson(s3Download))
      });
      me._webSocket?.send(JSON.stringify(DreamscapeMessage.toJson(notificationMessage)));
    }

    sendReplay = () => {
      const me = this;
      me._replay = false;
      if (!me.isOpen()) {
        return
      }
      const replayMessage = DreamscapeMessage.fromJson({
        action: WebSocketCommand.REPLAY,
        sessionId: me._sessionId
      });
      me._terminal?.clear();
      me._webSocket?.send(JSON.stringify(DreamscapeMessage.toJson(replayMessage)));
    }

    connect = () => {
      const me = this;
      me._dreamscapeApi.getSession({sessionId: me._sessionId}).then(resp => {
        if (resp.data.error) {
          me.showError({content: resp.data.error});
          return;
        }
        const session = resp.data.session!;
        // connecting only if session is not closed
        me._sessionState = session.sessionState as SessionState;
        me.setTitles(session);
        if (me._sessionState === SessionState.Closed) {
          me.disconnect(session);
          return;
        }
        const webSocketSetup = (federationData: string | undefined = undefined) => {
          getMidwayJwtToken().then(jwtToken => {
            me._webSocket = new WebSocket(`${me._webSocketBaseUrl}?sessionId=${me._sessionId}&authorizer=${jwtToken}`);
            me._webSocket.addEventListener("open", function (e: Event) {
              const startSessionRequest: StartSessionRequest = {
                sessionId: me._sessionId
              };
              if (federationData !== undefined) {
                startSessionRequest.federationData = federationData;
              }
              me._dreamscapeApi.startSession(startSessionRequest).then(resp => {
                if (resp.data.error) {
                  me.showError({content: resp.data.error});
                  return;
                }
                // immediately send ping once websocket connected, this takes session to grace mode
                me.sendPing();
                me._terminal?.clear();
              });
            });
            me._webSocket.addEventListener("message", (e: MessageEvent) => {
              const payload = DreamscapeMessage.fromJson(JSON.parse(e.data));
              const action = payload.action;
              const data = payload.data;
              if (payload.sessionId != me._sessionId) return;
              switch (action) {
                case WebSocketCommand.PING:
                  me.sendPong();
                  break;
                case WebSocketCommand.PONG:
                  if (!data) {
                    break;
                  }
                  const sessionPong = JSON.parse(data);
                  if (sessionPong.statusCode != 200) {
                    break;
                  }
                  const prevSessionState = me._sessionState;
                  const sessionState = sessionPong.sessionState;
                  me._sessionState = sessionState;
                  me.setTitles(session);
                  switch (sessionState) {
                    case SessionState.Active:
                      if (me._replay) {
                        me.sendResize();
                        me.sendReplay();
                      }
                      break;
                    case SessionState.Closed:
                      me.disconnect();
                      break;
                    case SessionState.Failed:
                      me.disconnect();
                      let errorMessage = "Session has failed";
                      if (sessionPong.sessionStateReason !== null && sessionPong.sessionStateReason !== "") {
                        errorMessage = sessionPong.sessionStateReason;
                      }
                      me.showError({content: errorMessage});
                      break;
                  }
                  break;
                case WebSocketCommand.DATA:
                  if (data && me.isSessionActive()) {
                    me._terminal?.write(decodeURIComponent(escape(atob(data))));
                  }
                  break;
                case WebSocketCommand.REPLAY:
                  me._terminal?.clear();
                  if (data) {
                    me._terminal?.write(decodeURIComponent(escape(atob(data))));
                  }
                  break;
                case WebSocketCommand.ACK:
                  if (me._sessionId === payload.sessionId && me._messageId === payload.messageId) {
                    me._messageId = undefined;
                    me.write("");
                  }
                  break;
                case WebSocketCommand.DRAIN:
                  if (!data) {
                    break;
                  }
                  const json = JSON.parse(data);
                  const drainAt = json.sessionDrainAt;
                  const date = new Date(drainAt);
                  this.showDrainCountdown(date);
                  break;

              }
            });
            me._webSocket.addEventListener("close", (e: Event) => {
              me.scheduleConnect();
            });
            me._webSocket.addEventListener("error", (e: Event) => {
            });
          });
        }

        if (session.federationAccountEmail && session.federationRoleName) {
          // @ts-ignore
          if (typeof document.getFederationAccountId !== "undefined" && typeof document.getAssumeRoleCredentials !== "undefined") {
            // @ts-ignore
            document.getFederationAccountId(session.fabric, session.availabilityZone, session.federationAccountEmail).then((accountId: string) => {
              // @ts-ignore
              document.getAssumeRoleCredentials(session.fabric, session.availabilityZone, accountId, session.federationRoleName).then((assumeRoleResult: string) => {
                webSocketSetup(assumeRoleResult);
              }).catch(() => {
                me.showError({content: "Dreamscape/Isengard federation has failed"});
                webSocketSetup();
              });
            })
              .catch(() => {
                me.showError({content: `Unable to find AWS account ID for ${session.federationAccountEmail}`});
                webSocketSetup();
              })
          } else {
            me.showError({content: "Dreamscape/Isengard federation requires user script installed"});
            webSocketSetup();
          }
        } else {
          webSocketSetup();
        }
      }).catch((e) => {
        me.showError({content: e});
      });
    }

    showDrainCountdown = (date: Date) => {
      const now = new Date()
      const diff = (date.valueOf() - now.valueOf())
      const minutes = Math.max(Math.floor(diff / (1000 * 60)), 0);
      const messageType = (minutes > 5) ? "warning" : "error";
      this.showError({
        id: sessionDrainMessageId,
        content: `Upcoming maintenance on the Dreamscape worker. This session is about to close in ${minutes} minutes.`,
        dismissible: false,
        type: messageType
      });
    }

    disconnect = (session: Session | undefined = undefined) => {
      const me = this;
      if (me._webSocket) {
        me._webSocket.close(1000, "exit");
        me._webSocket = undefined;
      }
      const analyzeSession = (session: Session) => {
        // these three fields should always be present
        const searchParamsObj: {} = {
          sessionType: session.sessionType,
          availabilityZone: session.availabilityZone,
          fabric: session.fabric,
        };
        if (session.macAddress) {
          searchParamsObj["macAddress"] = session.macAddress;
        }
        if (session.hardwareId) {
          searchParamsObj["hardwareId"] = session.hardwareId;
        }
        if (session.assetId) {
          searchParamsObj["assetId"] = session.assetId;
        }
        if (session.federationRoleName) {
          searchParamsObj["roleName"] = session.federationRoleName;
        }
        if (session.caz) {
          searchParamsObj["caz"] = session.caz;
        }
        if (session.ipAddressOverride && session.ipAddressOverrideReason) {
          searchParamsObj["ipAddressOverride"] = session.ipAddressOverride;
          searchParamsObj["ipAddressOverrideReason"] = session.ipAddressOverrideReason;
        }
        switch (session.sessionState) {
          case SessionState.Closed:
          case SessionState.Failed:
            const searchParams = new URLSearchParams(searchParamsObj).toString();
            me.showError({
              content: (
                <>
                  Session is now {session.sessionState}. Click{" "}
                  <Link color="inverted" href={`/create?${searchParams}`}>
                    here
                  </Link>
                  {" "}to restart in the new session.
                </>
              ),
              type: "info",
              dismissible: false
            }, true);
            break;
        }
      };
      if (session) {
        analyzeSession(session);
      } else {
        me._dreamscapeApi.getSession({sessionId: me._sessionId}).then(resp => {
          if (resp.data.error) {
            me.showError({content: resp.data.error});
            return;
          }
          const session = resp.data.session!;
          analyzeSession(session);
        }).catch((e) => {
          me.showError({content: e});
        });
      }
    }

    close = () => {
      const me = this;
      showLoading();
      me._dreamscapeApi.closeSession({sessionId: me._sessionId}).then(resp => {
        hideLoading();
        if (resp.data.error) {
          me.showError({content: resp.data.error});
          return;
        }
      }).catch((e) => {
        hideLoading();
        me.showError({content: e});
      });
    }

    write = (data: string) => {
      const me = this;
      if (me.isOpen() && me.isSessionActive()) {
        me._inputBuffer += data;
        if (me._messageId) {
          return;
        }
        let _data = me._inputBuffer;
        if (me._inputBuffer.length > DataLenLimit) {
          _data = me._inputBuffer.substring(0, DataLenLimit);
          me._inputBuffer = me._inputBuffer.substring(DataLenLimit);
        } else {
          me._inputBuffer = "";
        }
        if (!_data) {
          return;
        }
        me._messageId = v4();
        const dataMessage = DreamscapeMessage.fromJson({
          messageId: me._messageId,
          action: WebSocketCommand.DATA,
          sessionId: me._sessionId,
          data: btoa(unescape(encodeURIComponent(_data)))
        });
        me._webSocket?.send(JSON.stringify(DreamscapeMessage.toJson(dataMessage)));
      } else {
        me._messageId = undefined;
        me._inputBuffer = "";
      }
    }

    uploadFiles = async (files: File[]): Promise<string[]> => {
      const me = this;
      let errors: string[] = [];
      for (const file of files) {
        const contentType = file.type || 'application/octet-stream';
        let signedUrl: string | undefined = undefined;
        let bucket: string | undefined = undefined;
        let key: string | undefined = undefined;
        try {
          const resp = await me._dreamscapeApi.uploadFile({
            sessionId: me._sessionId,
            fileName: file.name,
            contentType: contentType,
          });
          if (resp.data.error) {
            me.showError({content: resp.data.error});
            continue;
          }
          signedUrl = resp.data.signedUrl;
          bucket = resp.data.bucket;
          key = resp.data.key;
        } catch (e) {
          errors.push(`${e}`);
          continue;
        }
        if (!signedUrl) {
          continue;
        }
        try {
          const uploadResp = await fetch(signedUrl, {
            method: "PUT",
            body: file,
            headers: {
              "Content-Type": contentType,
              "x-amz-acl": "bucket-owner-full-control",
            }
          });
          if (!uploadResp.ok) {
            errors.push(uploadResp.statusText);
          } else {
            if (bucket === undefined || bucket === "") {
              continue;
            }
            if (key === undefined || key === "") {
              continue
            }
            me.sendNotify(bucket, key)
          }
        } catch (e) {
          errors.push(`${e}`)
        }
      }
      return errors;
    }
  }

  const [webSocketAddonInstance, setWebSocketAddonInstance] = useState<WebSocketAddon | null>();

  const showUploadError = (message: FlashbarProps.MessageDefinition, clearPrevious: boolean = false) => {
    const newUploadFlash: FlashbarProps.MessageDefinition[] = [];
    if (!message.id) {
      message.id = v4();
    }
    if (!clearPrevious) {
      flash.forEach((item) => {
        if (item.id === message.id) {
          return;
        }
        newUploadFlash.push(item);
      });
    }
    if (!message.type) {
      message.type = "error";
    }
    if (message.dismissible === undefined) {
      message.dismissible = true;
    }
    if (message.dismissible) {
      message.onDismiss = () => {
        const newUploadFlash: FlashbarProps.MessageDefinition[] = [];
        uploadFlash.forEach(item => {
          if (item.id === message.id) {
            return;
          }
          newUploadFlash.push(item);
        });
        setUploadFlash(newUploadFlash);
      };
    }
    newUploadFlash.push(message);
    setUploadFlash(newUploadFlash);
  }

  useEffect(() => {
    const fitAddon = new FitAddon();
    document.title = `Dreamscape`;
    window.onresize = () => {
      fitAddon.fit();
    };
    const webSocketAddon = new WebSocketAddon(sessionId);
    setWebSocketAddonInstance(webSocketAddon);
    const instance = new Terminal({
      fontFamily: 'SFMono-Regular,SFMono,operator mono,Consolas,Liberation Mono,Menlo,monospace',
      fontSize: 13,
      theme: {
        foreground: "#c7c7c7",
        background: "#000000",
        cursor: "#c7c7c7",
        cursorAccent: "#feffff",
        selectionBackground: "#c6dcfc",
        selectionForeground: "#000000",
        black: "#000000",
        brightBlack: "#676767",
        red: "#b83019",
        brightRed: "#ef766d",
        green: "#51bf37",
        brightGreen: "#8cf67a",
        yellow: "#c6c43d",
        brightYellow: "#fefb7e",
        blue: "#0c24bf",
        brightBlue: "#6a71f6",
        magenta: "#b93ec1",
        brightMagenta: "#f07ef8",
        cyan: "#53c2c5",
        brightCyan: "#8ef9fd",
        white: "#c7c7c7",
        brightWhite: "#feffff"
      },
      cursorStyle: 'block',
      cursorBlink: false,
      scrollback: 100_000
    });
    const isWindows = ['Windows', 'Win16', 'Win32', 'WinCE'].indexOf(navigator.platform) !== -1;
    if (isWindows) {
      instance.attachCustomKeyEventHandler((event: KeyboardEvent) => {
        if (event.ctrlKey && event.type === "keydown") {
          // Support Windows ctrl+c for copying selected content
          // Note unless there is content selected, it will perform default SIGINT
          if (event.key === "c") {
            const selection = instance.getSelection();
            if (selection) {
              navigator.clipboard.writeText(selection);
              return false;
            }
            // Support Windows ctrl+v for pasting content
          } else if (event.key === "v") {
            return false;
            // Support Windows ctrl+a for highlighting all content
            // Although this overrides default action of moving to beginning of line
          } else if (event.key === "a") {
            const selection = instance.selectAll();
            return false;
          }
        }
        return true;
      });
    }
    instance.loadAddon(fitAddon);
    instance.loadAddon(webSocketAddon);
    instance.onData((data: string) => {
      webSocketAddon.write(data);
    });
    instance.onBinary((data: string) => {
      webSocketAddon.write(data);
    });
    instance.onResize((event: { cols: number; rows: number }) => {
      webSocketAddon.sendResize();
    });
    if (terminalRef.current) {
      // Mount terminal
      instance.open(terminalRef.current);
      instance.focus();
    }
    setTerminalInstance(instance);

    fitAddon.fit();
    return () => {
      instance.dispose();
      setTerminalInstance(null);
    };
  }, []);

  const topnavUtils = (): TopNavigationProps.Utility[] => {
    const utils: TopNavigationProps.Utility[] = [];
    if (webSocketAddonInstance?.isSessionActive()) {
      utils.push({
        type: "button",
        text: "Upload",
        iconName: "upload",
        onClick: () => {
          setUploadFlash([]);
          setFileUploadList([]);
          setShowFileUpload(true);
        }
      });
    }
    if (webSocketAddonInstance?.isClosable()) {
      utils.push({
        type: "button",
        text: "Close",
        iconName: "close",
        onClick: () => {
          setShowCloseSessionConfirm(true);
        }
      });
    }
    return utils;
  }

  useEffect(() => {
  }, [flash, uploadFlash]);

  const topnav = createRoot(document.getElementById('topnav')!);
  topnav.render(
    <>
      <TopNavigation
        i18nStrings={{
          searchIconAriaLabel: 'Search',
          searchDismissIconAriaLabel: 'Close search',
          overflowMenuTriggerText: 'More',
          overflowMenuTitleText: 'DS'
        }}
        identity={{
          href: '#',
          title: `${sessionName} ${sessionStateBullet(sessionState)}`,
          onFollow: () => {
            // show session information
          }
        }}
        utilities={topnavUtils()}
      />
    </>
  );

  return (
    <>
      <Modal
        visible={showCloseSessionConfirm}
        header={"Confirmation"}
        onDismiss={() => {
          setShowCloseSessionConfirm(false);
        }}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => {
                  setShowCloseSessionConfirm(false);
                }}
              >No</Button>
              <Button
                variant="primary"
                onClick={() => {
                  webSocketAddonInstance?.close();
                  setShowCloseSessionConfirm(false);
                }}
              >Yes</Button>
            </SpaceBetween>
          </Box>
        }
      >
        Are you sure you want to close this session?
      </Modal>
      <Modal
        visible={showFileUpload}
        onDismiss={() => {
          setShowFileUpload(false);
        }}
        closeAriaLabel={"Close modal"}
        size={"medium"}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="s">
              <Button variant="link" onClick={() => {
                setShowFileUpload(false);
              }}>
                Cancel
              </Button>
              <Button variant={"primary"} onClick={() => {
                showLoading();
                webSocketAddonInstance?.uploadFiles(fileUploadList).then(errors => {
                  hideLoading();
                  if (errors.length > 0) {
                    errors.forEach((e) => {
                      showUploadError({content: e});
                    });
                  } else {
                    setFileUploadList([]);
                    setShowFileUpload(false);
                  }
                }).catch(e => {
                  hideLoading();
                  showUploadError({content: e});
                });
              }}>
                Upload
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={"File Upload"}
      >
        <Flashbar
          items={uploadFlash}
        />
        <FormField description="Files cannot be larger than 160GB.">
          <FileUpload
            onChange={
              (event: NonCancelableCustomEvent<FileUploadProps.ChangeDetail>) => {
                setFileUploadList(event.detail.value);
              }
            }
            value={fileUploadList}
            i18nStrings={{
              uploadButtonText: e => e ? "Choose files" : "Choose file",
              dropzoneText: e => e ? "Drop files to upload" : "Drop file to upload",
              removeFileAriaLabel: e => `Remove file ${e + 1}`,
              limitShowFewer: "Show fewer files",
              limitShowMore: "Show more files",
              errorIconAriaLabel: "Error"
            }}
            showFileLastModified
            showFileSize={true}
            showFileThumbnail
            tokenLimit={3}
            multiple={true}
            constraintText=""/>
        </FormField>
      </Modal>
      <Flashbar
        items={flash}
      />
      <div
        style={{height: '100%'}}
        ref={terminalRef}
        data-testid="terminal-container"
      />
    </>
  );
}
