import {
  AppLayout,
  Box,
  Button,
  Checkbox,
  CheckboxProps,
  ColumnLayout,
  Container,
  ContentLayout,
  Flashbar,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  InputProps, KeyValuePairs,
  Link,
  Modal,
  NonCancelableCustomEvent,
  RadioGroup,
  RadioGroupProps,
  Select,
  SelectProps,
  SpaceBetween,
  Table
} from "@amzn/awsui-components-react";
import SideNav from "./SideNav";
import React, {useEffect, useState} from "react";
import {FlashbarProps} from "@amzn/awsui-components-react/polaris/flashbar/interfaces";
import {GetBMCInfoRequest, Session, SessionType,} from "../dreamscape-api/generated-src";
import DreamscapeApiFactory from "../dreamscape-api/DreamscapeApiFactory";
import {createRoot} from "react-dom/client";
import TopNav from "./TopNav";
import {
  GetAvailabilityZonesFromLocalStorage,
  GetFabricsFromLocalStorage,
  GetIsengardFederationScriptURL,
  GetUserFromLocalStorage
} from "../utils/dreamscape";
import {hideLoading, showLoading} from "./Loading";
import {getFederationAccountEmail, isEC2, isValidCAZ, isValidIPv4, isValidMAC} from "./Create";
import {BaseKeyDetail} from "@amzn/awsui-components-react/polaris/internal/events";
import {MAC, parseMAC} from "@ctrl/mac-address";
import {v4} from "uuid";

const BMC_CONNECT = "bmc";
const AZ_CONNECT = "az-fabric";
const AZ_FABRIC_SESSION_TYPES: SelectProps.Option[] = [
  {label: "Shell", value: SessionType.Shell, description: "Launch shell to execute arbitrary commands"},
];
const BMC_SPECIFIC_SESSION_TYPES: SelectProps.Option[] = [
  {label: "Inception", value: SessionType.Inception, description: "Launch Inception to selected BMC"},
  {label: "SOL", value: SessionType.Sol, description: "Launch SOL session to selected BMC"}
]
const BMC_SESSION_TYPES: SelectProps.Option[] = BMC_SPECIFIC_SESSION_TYPES.concat(AZ_FABRIC_SESSION_TYPES);

interface BMCAttr {
  label: string
  value: string
  readOnly: boolean
}

interface BMCAttrs {
  sessionType: BMCAttr
  macAddress: BMCAttr
  hardwareId: BMCAttr
  assetId: BMCAttr
  ipAddress: BMCAttr
  ipAddressOverride: BMCAttr
  ipAddressOverrideReason: BMCAttr
  availabilityZone: BMCAttr
  fabric: BMCAttr
  roleName: BMCAttr
  caz: BMCAttr

  consoleType: BMCAttr
  consoleStatus: BMCAttr
  sourceType: BMCAttr
  deviceType: BMCAttr
  productName: BMCAttr
  firmwareVersion: BMCAttr
  _smcIpAddresses: BMCAttr
}

export default function Sessions() {
  const [flash, setFlash] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [connectFlash, setConnectFlash] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [showConnectModal, setShowConnectModal] = useState<boolean>(false);
  const [connectFormType, setConnectFormType] = useState<string>(BMC_CONNECT);
  const [searchDisabled, setSearchDisabled] = useState<boolean>(false);
  const [connectDisabled, setConnectDisabled] = useState<boolean>(false);
  const [sessionTypes, setSessionTypes] = useState<SelectProps.Option[]>([]);
  const [selectedSessionType, setSelectedSessionType] = useState<SelectProps.Option | null>(null);
  const [ipAddressOverrideEnabled, setIpAddressOverrideEnabled] = useState<boolean>(false);
  const [federationDisabled, setFederationDisabled] = useState<boolean>(true);
  const [sessionTableLoading, setSessionTableLoading] = useState<boolean>(false);
  const [selectedSessions, setSelectedSessions] = useState<Session[]>([]);
  const [showCloseSessionsConfirm, setShowCloseSessionsConfirm] = useState<boolean>(false);

  const getBmcAttrs = (connectType: string, prevBmcAttrs: BMCAttrs | undefined = undefined): BMCAttrs => {
    let defaultSessionType = SessionType.Inception;
    if (connectType === BMC_CONNECT) {
      defaultSessionType = SessionType.Inception;
    } else if (connectType === AZ_CONNECT) {
      defaultSessionType = SessionType.Shell;
    }
    let sessionType = prevBmcAttrs?.sessionType.value || "";
    if (sessionTypes.map(st => st.value).indexOf(sessionType) == -1) {
      sessionType = defaultSessionType;
    }
    return {
      "sessionType": {
        label: "Session Type",
        value: sessionType,
        readOnly: false
      },
      "macAddress": {
        label: "MAC Address",
        value: "",
        readOnly: connectType !== BMC_CONNECT
      },
      "hardwareId": {
        label: "Hardware ID",
        value: "",
        readOnly: connectType !== BMC_CONNECT
      },
      "assetId": {
        label: "Asset ID",
        value: "",
        readOnly: connectType !== BMC_CONNECT
      },
      "ipAddress": {
        label: "IP Address",
        value: "",
        readOnly: true
      },
      "ipAddressOverride": {
        label: "IP Address",
        value: "",
        readOnly: true
      },
      "ipAddressOverrideReason": {
        label: "Reason",
        value: "",
        readOnly: connectType !== BMC_CONNECT
      },
      "availabilityZone": {
        label: "Availability Zone",
        value: (connectType === BMC_CONNECT) ? "" : prevBmcAttrs?.availabilityZone.value || "",
        readOnly: connectType === BMC_CONNECT
      },
      "fabric": {
        label: "Fabric",
        value: (connectType === BMC_CONNECT) ? "" : prevBmcAttrs?.fabric.value || "",
        readOnly: connectType === BMC_CONNECT
      },
      "roleName": {
        label: "Federation Role",
        value: prevBmcAttrs?.roleName.value || localStorage.getItem("roleName") || "",
        readOnly: false
      },
      "caz": {
        label: "Contingent Auth (Link to TT/SIM/MCM)",
        value: prevBmcAttrs?.caz.value || "",
        readOnly: false
      },
      "consoleType": {
        label: "Console Type",
        value: "",
        readOnly: true
      },
      "consoleStatus": {
        label: "Console Status",
        value: "",
        readOnly: true
      },
      "sourceType": {
        label: "Source Type",
        value: "",
        readOnly: true
      },
      "deviceType": {
        label: "Device Type",
        value: "",
        readOnly: true
      },
      "productName": {
        label: "Product Name",
        value: "",
        readOnly: true
      },
      "firmwareVersion": {
        label: "Firmware Version",
        value: "",
        readOnly: true
      },
      "_smcIpAddresses": {
        label: "SMCs",
        value: "",
        readOnly: true
      }
    }
  }
  const [bmcAttrs, setBmcAttrs] = useState<BMCAttrs>(getBmcAttrs(BMC_CONNECT));

  const showError = (message: FlashbarProps.MessageDefinition, clearPrevious: boolean = false) => {
    const newFlash: FlashbarProps.MessageDefinition[] = [];
    if (!message.id) {
      message.id = v4();
    }
    if (!clearPrevious) {
      flash.forEach((item) => {
        if (item.id === message.id) {
          return;
        }
        newFlash.push(item);
      });
    }
    if (!message.type) {
      message.type = "error";
    }
    if (message.dismissible === undefined) {
      message.dismissible = true;
    }
    if (message.dismissible) {
      message.onDismiss = () => {
        const newFlash: FlashbarProps.MessageDefinition[] = [];
        flash.forEach(item => {
          if (item.id === message.id) {
            return;
          }
          newFlash.push(item);
        });
        setFlash(newFlash);
      };
    }
    newFlash.push(message);
    setFlash(newFlash);
  }

  const showConnectError = (message: FlashbarProps.MessageDefinition, clearPrevious: boolean = false) => {
    const newConnectFlash: FlashbarProps.MessageDefinition[] = [];
    if (!message.id) {
      message.id = v4();
    }
    if (!clearPrevious) {
      flash.forEach((item) => {
        if (item.id === message.id) {
          return;
        }
        newConnectFlash.push(item);
      });
    }
    if (!message.type) {
      message.type = "error";
    }
    if (message.dismissible === undefined) {
      message.dismissible = true;
    }
    if (message.dismissible) {
      message.onDismiss = (a) => {
        const newConnectFlash: FlashbarProps.MessageDefinition[] = [];
        connectFlash.forEach(item => {
          if (item.id === message.id) {
            return;
          }
          newConnectFlash.push(item);
        });
        setConnectFlash(newConnectFlash);
      };
    }
    newConnectFlash.push(message);
    setConnectFlash(newConnectFlash)
  }

  const availabilityZones = GetAvailabilityZonesFromLocalStorage()
    .filter((az) => az.name !== undefined && az.name!.length > 3)
    .sort((az1, az2) => parseInt(az1.name!.substring(3)) - parseInt(az2.name!.substring(3)))
    .map((it) => {
      return {value: it.name, label: it.name}
    });
  const fabrics = GetFabricsFromLocalStorage().sort().map((it) => {
    return {value: it, label: it};
  });
  let azSessionTypeValues = AZ_FABRIC_SESSION_TYPES.map((st) => st.value);
  const azFabricDisabled = sessionTypes.map((st) => st.value).filter((st) => azSessionTypeValues.indexOf(st) >= 0).length == 0;

  const loadActiveSessionsWithDelay = (delay: number, showTableLoading: boolean = false) => {
    setTimeout(() => {
      loadActiveSessions(showTableLoading);
    }, delay);
  }

  const loadActiveSessions = (showTableLoading: boolean = false) => {
    if (showTableLoading) {
      setSessionTableLoading(true);
    }
    DreamscapeApiFactory().listActiveSessions().then(resp => {
      const sessionList = resp.data.sessions || [];
      setSessions(sessionList);
      const selectedSessionIdList = selectedSessions.map((s) => {
        return s.sessionId;
      });
      const newSelectedSessionList = sessionList.filter((s) => {
        return selectedSessionIdList.indexOf(s.sessionId) != -1;
      });
      setSessionTableLoading(false);
    }).catch(error => {
      let errorMessage = 'Error occurred getting list of open sessions: ';

      if (error.response) {
        if (error.response.status) {
          errorMessage += ` Status: ${error.response.status}`;
        }
      }

      errorMessage += ` Reason: ${error.message || error}`;

      if (!('userName' in GetUserFromLocalStorage())) {
        showError({
          content: `Unable to verify user! Make sure you are connected to corp fabric or connected via VPN`,
          dismissible: false
        }, true);
      } else {
        showError({
          content: errorMessage,
          dismissible: false
        }, true);
      }
    });
  };

  const changeConnectFormType = (value: string, prevBmcAtts: BMCAttrs | undefined = undefined) => {
    if (value === BMC_CONNECT) {
      const newBmcAttrs = getBmcAttrs(BMC_CONNECT, prevBmcAtts);
      setConnectFormType(BMC_CONNECT);
      setSearchDisabled(false);
      setConnectDisabled(true);
      setIpAddressOverrideEnabled(false);

      // sorting out session type
      setSessionTypes(BMC_SESSION_TYPES);
      let sst = BMC_SESSION_TYPES.find((o) => o.value === newBmcAttrs.sessionType.value) || BMC_SESSION_TYPES[0];
      setSelectedSessionType(sst);
      newBmcAttrs.sessionType.value = sst.value!;

      // setting BMC attributes
      setBmcAttrs(newBmcAttrs);
    } else if (value === AZ_CONNECT) {
      const newBmcAttrs = getBmcAttrs(AZ_CONNECT, prevBmcAtts);
      setConnectFormType(AZ_CONNECT);
      setSearchDisabled(true);
      setConnectDisabled(false);
      setIpAddressOverrideEnabled(false);

      // sorting out session type
      setSessionTypes(AZ_FABRIC_SESSION_TYPES);
      let sst = AZ_FABRIC_SESSION_TYPES.find((o) => o.value === newBmcAttrs.sessionType.value) || AZ_FABRIC_SESSION_TYPES[0];
      setSelectedSessionType(sst);
      newBmcAttrs.sessionType.value = sst.value!;

      // setting BMC attributes
      setBmcAttrs(newBmcAttrs);
    }
  }

  const showConnectForm = () => {
    setConnectFlash([]);
    changeConnectFormType(BMC_CONNECT);
    setShowConnectModal(true);
  }

  const getBmcInfo = () => {
    const macAddress = bmcAttrs.macAddress.value;
    const hardwareId = bmcAttrs.hardwareId.value;
    const assetId = bmcAttrs.assetId.value;
    if ([macAddress, hardwareId, assetId].every(v => v === undefined || v === "")) {
      return;
    }
    const getBMCInfoRequest: GetBMCInfoRequest = {};
    if (macAddress !== undefined && macAddress !== "") {
      getBMCInfoRequest.macAddress = macAddress;
    }
    if (hardwareId !== undefined && hardwareId !== "") {
      getBMCInfoRequest.hardwareId = hardwareId;
    }
    if (assetId !== undefined && assetId !== "") {
      getBMCInfoRequest.assetId = assetId;
    }
    showLoading();
    DreamscapeApiFactory().getBMCInfo(getBMCInfoRequest).then(resp => {
      hideLoading();
      if (resp.data.error) {
        showConnectError({content: resp.data.error});
        if (resp.data.errors) {
          resp.data.errors.forEach((e: string) => {
            showConnectError({content: e});
          })
        }
        return;
      }
      const bmc = resp.data.bmc!;
      if (bmc) {
        const newBmcAttrs = getBmcAttrs(BMC_CONNECT, bmcAttrs);
        type bmcAttrKey = keyof typeof newBmcAttrs;
        for (let k in newBmcAttrs) {
          if (bmc.hasOwnProperty(k)) {
            if (bmc[k]) {
              newBmcAttrs[k as bmcAttrKey].value = bmc[k];
            }
          }
        }
        if (bmc.hasOwnProperty("smcIpAddresses")) {
          if (bmc["smcIpAddresses"]) {
            newBmcAttrs["_smcIpAddresses"].value = bmc["smcIpAddresses"].join(", ");
          }
        }
        // resetting IP override values
        newBmcAttrs.ipAddressOverride.value = bmcAttrs.ipAddress.value;
        newBmcAttrs.ipAddressOverrideReason.value = "";
        setConnectFlash([]);
        setConnectDisabled(false);
        setFederationDisabled(!isEC2(newBmcAttrs.fabric.value));
        setBmcAttrs(newBmcAttrs);
      }
    }).catch(reason => {
      hideLoading();
      if(String(reason).includes("status code 404")) {
        showConnectError({content: "BMC not found."});
      } else {
        showConnectError({content: "Error occurred getting BMC information..."});
      }
      console.error(reason);
    });
  }

  const validate = (): boolean => {
    const sessionType = bmcAttrs.sessionType.value || undefined;
    let macAddress = bmcAttrs.macAddress.value || undefined;
    const hardwareId = bmcAttrs.hardwareId.value || undefined;
    const assetId = bmcAttrs.assetId.value || undefined;
    const roleName = bmcAttrs.roleName.value || undefined;
    const availabilityZone = bmcAttrs.availabilityZone.value || undefined;
    const fabric = bmcAttrs.fabric.value || undefined;
    const caz = bmcAttrs.caz.value || undefined;
    const ipAddressOverride = bmcAttrs.ipAddressOverride.value || undefined;
    const ipAddressOverrideReason = bmcAttrs.ipAddressOverrideReason.value || undefined;

    if (!sessionType) {
      showConnectError({
        content: "You must select a session type",
        dismissible: true,
      });
      return false;
    }
    if (!isValidCAZ(caz)) {
      showConnectError({
        content: "You must provide a link to a valid TT/SIM/MCM for CAZ",
        dismissible: true,
      });
      return false;
    }
    if (isValidMAC(macAddress) && macAddress) {
      try {
        macAddress = parseMAC(macAddress!).toString({zeroPad: true}).toUpperCase();
      } catch (e) {
        macAddress = undefined;
      }
    }
    if (ipAddressOverrideReason && ipAddressOverride) {
      if (ipAddressOverrideReason === "") {
        showConnectError({
          content: "Missing required parameter: Reason for IP address override",
          dismissible: true,
        });
        return false;
      }
      if (!isValidIPv4(ipAddressOverride)) {
        showConnectError({
          content: `${ipAddressOverride} is not a valid IPv4 address`,
          dismissible: true,
        });
        return false;
      }
    }
    let bmcConnect = true;
    if ([macAddress, hardwareId, assetId].every(v => v === undefined || v === "")) {
      bmcConnect = false;
    }
    if (!availabilityZone) {
      showConnectError({
        content: "Missing required parameter: Availability Zone",
        dismissible: true,
      });
      return false;
    }
    if (!fabric) {
      showConnectError({
        content: "Missing required parameter: Fabric",
        dismissible: true,
      });
      return false;
    }
    if (isEC2(fabric) && roleName) {
      localStorage.setItem("roleName", roleName);
    }
    return true;
  }

  const create = () => {
    type bmcAttrKey = keyof typeof bmcAttrs;
    const searchParamsObj = {};
    for (let k in bmcAttrs) {
      const val = bmcAttrs[k as bmcAttrKey].value;
      if (val) {
        searchParamsObj[k] = val;
      }
    }
    const searchParams = new URLSearchParams(searchParamsObj).toString();
    window.open(`/create?${searchParams}`, '_blank');
  }

  const closeSessions = async (sessionsToClose: Session[]) => {
    const dreamscapeApi = DreamscapeApiFactory();
    for (const session of sessionsToClose) {
      try {
        const resp = await dreamscapeApi.closeSession({sessionId: session.sessionId});
        if (resp.data.error) {
          showError({content: resp.data.error});
        }
      } catch (e) {
        showError({content: `${e}`});
      }
    }
  }

  useEffect(() => {
    const topnav = createRoot(document.getElementById('topnav')!);
    topnav.render(<TopNav/>);
    // @ts-ignore
    if (typeof document.getFederationAccountId === "undefined" || typeof document.getAssumeRoleCredentials === "undefined") {
      showError({
        type: "warning",
        content: (
          <>
            Isengard federation user script is missing in your browser. Install <Link external
                                                                                      href={GetIsengardFederationScriptURL()}>here</Link>
          </>
        ),
        dismissible: false
      })
    }
    loadActiveSessions(true);
  }, []);

  useEffect(() => {
  }, [flash, connectFlash]);

  return (
    <AppLayout
      navigationWidth={200}
      navigation={<SideNav/>}
      headerSelector={"#topnav"}
      toolsHide={true}
      content={
        <ContentLayout
          header={
            <Header
              variant="h1"
              info={<Link variant="info">Info</Link>}
              data-testid="sessions-heading"
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant={"normal"}
                    disabled={selectedSessions.length == 0}
                    onClick={() => {
                      setShowCloseSessionsConfirm(true);
                    }}
                  >Close{(selectedSessions.length == 0) ? '' : ` ${selectedSessions.length} Selected`}</Button>
                  <Button
                    variant={"normal"}
                    iconName={"refresh"}
                    onClick={() => {
                      loadActiveSessions(true);
                    }}
                  />
                  <Button
                    variant={"primary"}
                    onClick={() => {
                      showConnectForm();
                    }}
                  >New Session</Button>
                </SpaceBetween>}
            >
              Sessions
            </Header>
          }
          notifications={
            <Flashbar
              items={flash}
            />
          }
        >
          <Modal
            visible={showCloseSessionsConfirm}
            header={"Confirmation"}
            onDismiss={() => {
              setShowCloseSessionsConfirm(false);
              loadActiveSessions(true);
            }}
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="link"
                    onClick={() => {
                      setShowCloseSessionsConfirm(false);
                    }}
                  >No</Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setShowCloseSessionsConfirm(false);
                      setSelectedSessions([]);
                      showLoading();
                      closeSessions(selectedSessions).then(() => {
                        hideLoading();
                        loadActiveSessions(true);
                      }).catch(() => {
                        hideLoading();
                      });
                    }}
                  >Yes</Button>
                </SpaceBetween>
              </Box>
            }
          >
            Are you sure you want to
            close {selectedSessions.length} {(selectedSessions.length == 1) ? "session" : "sessions"}?
          </Modal>
          <Modal
            visible={showConnectModal}
            header={"New Session"}
            size={"large"}
            onDismiss={() => {
              setShowConnectModal(false);
              loadActiveSessions(true);
            }}
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant={"primary"}
                    disabled={connectDisabled}
                    onClick={() => {
                      if (validate()) {
                        create();
                        setShowConnectModal(false);
                        loadActiveSessionsWithDelay(2500, true);
                      }
                    }}>
                    Connect
                  </Button>
                </SpaceBetween>
              </Box>
            }
          >
            <Flashbar
              items={connectFlash}
            />
            <Form>
              <ColumnLayout columns={2}>
                <div>
                  <RadioGroup
                    value={connectFormType}
                    onChange={(event: NonCancelableCustomEvent<RadioGroupProps.ChangeDetail>) => changeConnectFormType(event.detail.value, bmcAttrs)}
                    items={[
                      {value: BMC_CONNECT, label: "BMC", description: "Limited access to single BMC"},
                    ]}
                  />
                  <Container>
                    <SpaceBetween size={"s"}>
                      <FormField
                        label={bmcAttrs.hardwareId.label}>
                        <Input
                          onChange={
                            (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                              const newBmcAttrs = getBmcAttrs(connectFormType, bmcAttrs);
                              newBmcAttrs.hardwareId.value = event.detail.value;
                              setSearchDisabled(false);
                              setConnectDisabled(true);
                              setBmcAttrs(newBmcAttrs);
                            }
                          }
                          onKeyUp={
                            (event: CustomEvent<BaseKeyDetail>) => {
                              if (event.detail.keyCode == 13) {
                                getBmcInfo();
                              }
                            }
                          }
                          value={bmcAttrs.hardwareId.value}
                          type={"search"}
                          readOnly={bmcAttrs.hardwareId.readOnly}
                        />
                      </FormField>
                      <FormField
                        label={bmcAttrs.assetId.label}>
                        <Input
                          onChange={
                            (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                              const newBmcAttrs = getBmcAttrs(connectFormType, bmcAttrs);
                              newBmcAttrs.assetId.value = event.detail.value;
                              setSearchDisabled(false);
                              setConnectDisabled(true);
                              setBmcAttrs(newBmcAttrs);
                            }
                          }
                          onKeyUp={
                            (event: CustomEvent<BaseKeyDetail>) => {
                              if (event.detail.keyCode == 13) {
                                getBmcInfo();
                              }
                            }
                          }
                          value={bmcAttrs.assetId.value}
                          type={"search"}
                          readOnly={bmcAttrs.assetId.readOnly}
                        />
                      </FormField>
                      <FormField
                        label={bmcAttrs.macAddress.label}>
                        <Input
                          onChange={
                            (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                              let macAddress: MAC | undefined;
                              try {
                                macAddress = parseMAC(event.detail.value);
                              } catch (e) {
                                macAddress = undefined;
                              }
                              const newBmcAttrs = getBmcAttrs(connectFormType, bmcAttrs);
                              if (macAddress) {
                                newBmcAttrs.macAddress.value = macAddress.toString({zeroPad: true}).toUpperCase();
                              } else {
                                newBmcAttrs.macAddress.value = event.detail.value;
                              }
                              setSearchDisabled(false);
                              setConnectDisabled(true);
                              setBmcAttrs(newBmcAttrs);
                            }
                          }
                          onKeyUp={
                            (event: CustomEvent<BaseKeyDetail>) => {
                              if (event.detail.keyCode == 13 && isValidMAC(bmcAttrs.macAddress.value)) {
                                getBmcInfo();
                              }
                            }
                          }
                          value={bmcAttrs.macAddress.value}
                          type={"search"}
                          readOnly={bmcAttrs.macAddress.readOnly}
                          invalid={!isValidMAC(bmcAttrs.macAddress.value)}
                        />
                      </FormField>
                      <FormField
                        label={bmcAttrs.ipAddress.label}>
                        <Grid>
                          <Input
                            onChange={
                              (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                                const newBmcAttrs = {...bmcAttrs};
                                newBmcAttrs.ipAddressOverride.value = event.detail.value;
                                setBmcAttrs(newBmcAttrs);
                              }
                            }
                            value={(ipAddressOverrideEnabled) ? bmcAttrs.ipAddressOverride.value : bmcAttrs.ipAddress.value}
                            type={"text"}
                            readOnly={!ipAddressOverrideEnabled}
                            disabled={!ipAddressOverrideEnabled}
                          />
                          <Checkbox
                            disabled={connectDisabled || connectFormType !== BMC_CONNECT}
                            checked={ipAddressOverrideEnabled}
                            onChange={
                              (event: NonCancelableCustomEvent<CheckboxProps.ChangeDetail>) => {
                                const newBmcAttrs = {...bmcAttrs};
                                newBmcAttrs.ipAddressOverride.value = newBmcAttrs.ipAddress.value;
                                newBmcAttrs.ipAddressOverrideReason.value = "";
                                setIpAddressOverrideEnabled(event.detail.checked);
                                setBmcAttrs(newBmcAttrs);
                              }
                            }>
                            Override
                          </Checkbox>
                        </Grid>
                      </FormField>
                      <FormField
                        label={bmcAttrs.ipAddressOverrideReason.label}>
                        <Input
                          onChange={
                            (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                              const newBmcAttrs = {...bmcAttrs};
                              newBmcAttrs.ipAddressOverrideReason.value = event.detail.value;
                              setBmcAttrs(newBmcAttrs);
                            }
                          }
                          value={bmcAttrs.ipAddressOverrideReason.value}
                          type={"text"}
                          readOnly={!ipAddressOverrideEnabled}
                        />
                      </FormField>
                      <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button
                            variant={"primary"}
                            iconName={"search"}
                            disabled={searchDisabled}
                            onClick={() => {
                              getBmcInfo();
                            }}>
                            Search
                          </Button>
                        </SpaceBetween>
                      </Box>
                    </SpaceBetween>
                  </Container>
                </div>
                <div>
                  <RadioGroup
                    value={connectFormType}
                    onChange={(event: NonCancelableCustomEvent<RadioGroupProps.ChangeDetail>) => changeConnectFormType(event.detail.value, bmcAttrs)}
                    items={[
                      {
                        value: AZ_CONNECT,
                        label: "Availability Zone/Fabric",
                        description: "Access to every BMC in the selected zone/fabric",
                        disabled: azFabricDisabled
                      }
                    ]}
                  />
                  <Container>
                    <SpaceBetween size={"s"}>
                      <FormField
                        label={bmcAttrs.availabilityZone.label}>
                        <Select
                          selectedOption={availabilityZones.find((o) => o.value === bmcAttrs.availabilityZone.value) || null}
                          onChange={
                            (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
                              const newBmcAttrs = {...bmcAttrs};
                              newBmcAttrs.availabilityZone.value = event.detail.selectedOption.value || availabilityZones[0].value || "";
                              setBmcAttrs(newBmcAttrs);
                            }
                          }
                          options={availabilityZones}
                          disabled={bmcAttrs.availabilityZone.readOnly}
                        />
                      </FormField>
                      <FormField
                        label={bmcAttrs.fabric.label}>
                        <Select
                          selectedOption={fabrics.find((o) => o.value === bmcAttrs.fabric.value) || null}
                          onChange={
                            (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
                              const newBmcAttrs = {...bmcAttrs};
                              newBmcAttrs.fabric.value = event.detail.selectedOption.value || fabrics[0].value || "";
                              setFederationDisabled(!isEC2(newBmcAttrs.fabric.value));
                              setBmcAttrs(newBmcAttrs);
                            }
                          }
                          options={fabrics}
                          disabled={bmcAttrs.fabric.readOnly}
                        />
                      </FormField>
                    </SpaceBetween>
                  </Container>
                  <Container
                    variant={"stacked"}
                    header={
                      <Header headingTagOverride="h3">
                        Console Info
                      </Header>
                    }
                  >
                    <KeyValuePairs
                      columns={3}
                      items={[
                        {label: bmcAttrs.consoleType.label, value: bmcAttrs.consoleType.value},
                        {label: bmcAttrs.consoleStatus.label, value: bmcAttrs.consoleStatus.value},
                        {label: bmcAttrs.sourceType.label, value: bmcAttrs.sourceType.value},
                        {label: bmcAttrs.deviceType.label, value: bmcAttrs.deviceType.value},
                        {label: bmcAttrs.productName.label, value: bmcAttrs.productName.value},
                        {label: bmcAttrs.firmwareVersion.label, value: bmcAttrs.firmwareVersion.value},
                      ].concat((bmcAttrs._smcIpAddresses.value) ? [{
                        label: bmcAttrs._smcIpAddresses.label,
                        value: bmcAttrs._smcIpAddresses.value
                      }] : [])}
                    />
                  </Container>
                </div>
                <SpaceBetween size={"s"}>
                  <FormField
                    label={bmcAttrs.sessionType.label}>
                    <Select
                      selectedOption={selectedSessionType}
                      onChange={
                        (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
                          const sst = event.detail.selectedOption.value;
                          if (sst) {
                            const newBmcAttrs = {...bmcAttrs};
                            newBmcAttrs.sessionType.value = sst;
                            setBmcAttrs(newBmcAttrs);
                            setSelectedSessionType(event.detail.selectedOption);
                          }
                        }
                      }
                      options={sessionTypes}
                      ariaRequired={true}
                    />
                  </FormField>
                  <FormField
                    label={bmcAttrs.caz.label}>
                    <Input
                      value={bmcAttrs.caz.value}
                      ariaRequired={true}
                      invalid={!isValidCAZ(bmcAttrs.caz.value)}
                      onChange={
                        (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                          const newBmcAttrs = {...bmcAttrs};
                          newBmcAttrs.caz.value = event.detail.value;
                          setBmcAttrs(newBmcAttrs);
                        }
                      }
                    />
                  </FormField>
                </SpaceBetween>
                <SpaceBetween size={"s"}>
                  <FormField
                    label={"Federation Account"}>
                    <Input disabled={federationDisabled}
                           value={getFederationAccountEmail(bmcAttrs.availabilityZone.value, bmcAttrs.fabric.value)}/>
                  </FormField>
                  <FormField
                    label={bmcAttrs.roleName.label}>
                    <Input
                      disabled={federationDisabled}
                      value={bmcAttrs.roleName.value}
                      onChange={
                        (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                          const newBmcAttrs = {...bmcAttrs};
                          newBmcAttrs.roleName.value = event.detail.value;
                          setBmcAttrs(newBmcAttrs);
                        }
                      }
                    />
                  </FormField>
                </SpaceBetween>
              </ColumnLayout>
            </Form>
          </Modal>
          <Table
            items={sessions}
            loading={sessionTableLoading}
            loadingText={"Loading..."}
            onSelectionChange={({detail}) =>
              setSelectedSessions(detail.selectedItems)
            }
            selectedItems={selectedSessions}
            enableKeyboardNavigation
            selectionType={"multi"}
            trackBy="sessionId"
            resizableColumns
            stickyHeader={true}
            contentDensity={"comfortable"}
            header={
              <Header
                counter={selectedSessions.length ? `(${selectedSessions.length}/${sessions.length})` : `(${sessions.length})`}>
                Open Sessions
              </Header>
            }
            columnDefinitions={[
              {
                id: "sessionId",
                header: "Session ID",
                cell: item => <Link href={`/connect/${item.sessionId}`} external>{item.sessionId}</Link>
              },
              {
                id: "sessionName",
                header: "Session Name",
                cell: item => item.sessionName
              },
              {
                id: "sessionState",
                header: "Session State",
                cell: item => item.sessionState
              },
              {
                id: "hardwareId",
                header: "Hardware ID",
                cell: item => (item.sessionIsolation) ? item.hardwareId : '-'
              },
              {
                id: "assetId",
                header: "Asset ID",
                cell: item => (item.sessionIsolation) ? item.assetId : '-'
              },
              {
                id: "availabilityZone",
                header: "Availability Zone",
                cell: item => item.availabilityZone
              },
              {
                id: "fabric",
                header: "Fabric",
                cell: item => item.fabric
              },
            ]}
            empty={
              <Box
                margin={{vertical: "xs"}}
                textAlign="center"
                color="inherit"
              >
                <SpaceBetween size="m">
                  <b>No sessions</b>
                  <Button
                    onClick={() => {
                      showConnectForm();
                    }}
                  >New session</Button>
                </SpaceBetween>
              </Box>
            }
          />
        </ContentLayout>
      }
    />
  );
}
