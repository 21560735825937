import React, { useEffect, useState } from "react";
import { TopNavigationProps } from "@amzn/awsui-components-react/uxdg/top-navigation/interfaces";
import Utility = TopNavigationProps.Utility;
import { TopNavigation } from "@amzn/awsui-components-react";
import { applyMode, Mode } from "@amzn/awsui-global-styles";
import { GetRegionsFromLocalStorage, GetUserFromLocalStorage } from "../utils/dreamscape";
import { Regions, User } from "../dreamscape-api/generated-src";

interface TopNavState {
  user: User
  regions: Regions
  topNavUtils: Utility[]
}

export default function TopNav() {
  const [navRegions, setNavRegions] = useState(GetRegionsFromLocalStorage());
  const currentRegion = navRegions.currentRegion || {};
  const regions = navRegions.regions?.map(it => it) || [];
  const [user, setUser] = useState(GetUserFromLocalStorage());
  const [darkMode, setDarkMode] = useState(localStorage.getItem("darkMode") === "true");
  const domainSuffix = window.location.hostname.replace(new RegExp(`^${currentRegion?.airportCode?.toLowerCase()}[.]`), '')
  const topNavUtils: Utility[] = [
      {
        type: "menu-dropdown",
        iconName: "settings",
        ariaLabel: "Settings",
        title: "Settings",
        items: [
          {
            id: "settings-org",
            text: "Application Settings"
          },
          {
            id: "darkmode-toggle",
            text: "Toggle Dark Mode"
          }
        ],
        onItemClick: (event) => {
          if (event.detail.id === "darkmode-toggle") {
            toggleDarkMode();
          }
        }
      },
      {
        type: "menu-dropdown",
        text: user.userName,
        iconName: "user-profile",
        items: []
      },
      {
        type: "menu-dropdown",
        text: `${currentRegion?.airportCode} (${currentRegion?.regionName})`,
        items: regions.sort((a, b) => {
          return (a.airportCode || "").localeCompare(b.airportCode || "")
        }).map((r) => {
            return {
              id: r.airportCode || "",
              text: `${r.airportCode} (${r.regionName})`,
              href: `https://${r.airportCode?.toLocaleLowerCase()}.${domainSuffix.toLowerCase()}`
            }
          }
        )
      }
    ];

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    localStorage.setItem("darkMode", (!darkMode).toString());
  }

  useEffect(() => {
    if (darkMode) {
      applyMode(Mode.Dark);
    } else {
      applyMode(Mode.Light);
    }
  }, [darkMode])

  return (
    <TopNavigation
      i18nStrings={{
        searchIconAriaLabel: 'Search',
        searchDismissIconAriaLabel: 'Close search',
        overflowMenuTriggerText: 'More',
        overflowMenuTitleText: 'DS'
      }}
      identity={{
        href: '/',
        title: 'Dreamscape'
      }}
      utilities={topNavUtils}
    />
  );
}
