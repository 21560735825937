import { SideNavigation } from "@amzn/awsui-components-react";
import { useLocation, useNavigate } from "react-router";
import React from "react";


export default function SideNav() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <SideNavigation
      items={[
        {
          type: "section",
          text: "Console Bastion",
          items: [
            { type: "link", text: "Sessions", href: "/sessions" }
          ]
        }
      ]}
      onFollow={(event) => {
        if (!event.detail.external) {
          event.preventDefault();
          navigate({ pathname: event.detail.href }, { state: location.key });
        }
      }}
    />
  );
}
