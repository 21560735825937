import { AvailabilityZone, Regions, User } from "../dreamscape-api/generated-src";

export function GetUserFromLocalStorage(): User {
  let userPlainObject = {};
  try {
    userPlainObject = JSON.parse(localStorage.getItem("user") || "{}");
  } catch (e) {
    return {}
  }
  return userPlainObject;
}

export function GetRegionsFromLocalStorage(): Regions {
  let regionsPlainObject = {};
  try {
    regionsPlainObject = JSON.parse(localStorage.getItem("regions") || "{}");
  } catch (e) {
    return {}
  }
  return regionsPlainObject
}

export function GetAvailabilityZonesFromLocalStorage(): Array<AvailabilityZone> {
  let regionsPlainObject = {};
  try {
    regionsPlainObject = JSON.parse(localStorage.getItem("regions") || "{}");
  } catch (e) {
    return []
  }
  const regions: Regions = regionsPlainObject;
  return regions.currentRegion?.availabilityZones || [];
}

export function GetFabricsFromLocalStorage(): Array<string> {
  let regionsPlainObject = {};
  try {
    regionsPlainObject = JSON.parse(localStorage.getItem("regions") || "{}");
  } catch (e) {
    return []
  }
  const regions: Regions = regionsPlainObject;
  return regions.currentRegion?.fabrics || [];
}

export function GetIsengardFederationScriptURL(): string {
  let tavernEndpoint = localStorage.getItem("tavernEndpoint") || "tavern.corp.amazon.com";
  return `https://${tavernEndpoint}/dubbel/api/download/DCO-LIVE/hweng/dreamscape/ConsoleDreamscapeIsengardFederation.user.js`;
}
