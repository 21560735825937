import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import {CreateSessionRequest, DefaultApi, Fabric, SessionType} from "../dreamscape-api/generated-src";
import DreamscapeApiFactory from "../dreamscape-api/DreamscapeApiFactory";
import {parseMAC} from "@ctrl/mac-address";
import {ContentLayout, Flashbar} from "@amzn/awsui-components-react";
import {FlashbarProps} from "@amzn/awsui-components-react/polaris/flashbar/interfaces";
import {v4} from "uuid";

export function isEC2(fabric: string | undefined): boolean {
  return [Fabric.Ec2, Fabric.Vec2, Fabric.Treadmill, Fabric.Ulz].indexOf(fabric as Fabric) > -1;
}

export function isValidCAZ(caz: string | undefined | null): boolean {
  if (!caz) {
    return false;
  }
  const endpoints = "(i|issues|t\.(corp|zhy)|tt|mcm|sim-ticketing-fleet|myday-website([.-][a-z]+)?|sim|(app\.)?boost)"
  const domains = "\.(((aka\.corp\.|.*\.proxy\.)?amazon\.com)|aws-border\.(cn|com)|us-iso-east-1\.c2s\.ic\.gov|us-isob-east-1\.sc2s\.sgov\.gov|cn-northwest-1\.amazonaws\.cn|ncl\.aws-border\.adc-e\.uk|ale\.aws-border\.hci\.ic\.gov|aws\.a2z\.com)"
  const cazRegex = new RegExp(`^https:\/\/${endpoints}?${domains}\/(issues\/|cms\/|ticket\/|platform\/work-requests\/)?([-0-9a-z]+)([\/?#].*)*$`, "i");
  return cazRegex.test(caz);
}

export function isValidMAC(mac: string | undefined | null): boolean {
  if (!mac) {
    return true;
  }
  try {
    parseMAC(mac);
    return true;
  } catch (e) {
    return false;
  }
}

export function isValidIPv4(ip: string): boolean {
  const v4 = '(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)){3}';
  const v4exact = new RegExp(`^${v4}$`);
  return v4exact.test(ip);
}

export function getFederationAccountEmail(availabilityZone: string | undefined | null, fabric: string | undefined | null) {
  if (!fabric) {
    return "";
  }
  if (!availabilityZone) {
    return "";
  }
  if (!isEC2(fabric)) {
    return "";
  }
  const emailDomain = localStorage.getItem("emailDomain") || "amazon.com";
  const stage = localStorage.getItem("stage") || "prod";
  const isNaws = fabric == Fabric.Treadmill || fabric == Fabric.Vec2 || fabric == Fabric.Awsedge || fabric == Fabric.Ulz;
  if (isNaws) {
    return `naws-gts-operator-${stage.toLowerCase()}-${availabilityZone.toLowerCase()}@${emailDomain}`;
  } else {
    return `ec2-gts+operator+${availabilityZone.toUpperCase()}@${emailDomain}`;
  }
}

export default function Create() {
  let [flash, setFlash] = useState<FlashbarProps.MessageDefinition[]>(
    [
      {
        content: "Creating session...",
        dismissible: false,
        type: "in-progress"
      }
    ]
  );
  const location = useLocation();
  const navigate = useNavigate();
  const search = location.search.slice(1);
  const query = new URLSearchParams(search);

  const showError = (message: FlashbarProps.MessageDefinition, clearPrevious: boolean = false) => {
    const newFlash: FlashbarProps.MessageDefinition[] = [];
    if (!message.id) {
      message.id = v4();
    }
    if (!clearPrevious) {
      flash.forEach((item) => {
        if (item.id === message.id) {
          return;
        }
        newFlash.push(item);
      });
    }
    if (!message.type) {
      message.type = "error";
    }
    if (message.dismissible === undefined) {
      message.dismissible = true;
    }
    if (message.dismissible) {
      message.onDismiss = () => {
        const newFlash: FlashbarProps.MessageDefinition[] = [];
        flash.forEach(item => {
          if (item.id === message.id) {
            return;
          }
          newFlash.push(item);
        });
        setFlash(newFlash);
      };
    }
    newFlash.push(message);
    setFlash(newFlash);
  }

  useEffect(() => {
    const sessionType = query.get("sessionType") || undefined;
    let macAddress = query.get("macAddress") || undefined;
    const hardwareId = query.get("hardwareId") || undefined;
    const assetId = query.get("assetId") || undefined;
    const roleName = query.get("roleName") || undefined;
    const availabilityZone = query.get("availabilityZone") || undefined;
    const fabric = query.get("fabric") || undefined;
    const caz = query.get("caz") ? decodeURIComponent(query.get("caz") || "") : undefined;
    const ipAddressOverride = query.get("ipAddressOverride") || undefined;
    const ipAddressOverrideReason = query.get("ipAddressOverrideReason") || undefined;

    if (!sessionType) {
      showError({
        content: "You must select session type",
        dismissible: false,
      });
      return;
    }
    if (!isValidCAZ(caz)) {
      showError({
        content: "You must provide link to TT/SIM/MCM for CAZ",
        dismissible: false,
      });
      return;
    }
    if (isValidMAC(macAddress) && macAddress) {
      try {
        macAddress = parseMAC(macAddress!).toString({zeroPad: true}).toUpperCase();
      } catch (e) {
        macAddress = undefined;
      }
    }
    const createSessionRequest: CreateSessionRequest = {
      sessionType: sessionType as SessionType,
      caz: caz
    }
    if (ipAddressOverrideReason && ipAddressOverride) {
      if (ipAddressOverrideReason === "") {
        showError({
          content: "You have not provided reason why IP address is overwritten",
          dismissible: false,
        });
        return;
      }
      if (!isValidIPv4(ipAddressOverride)) {
        showError({
          content: `${ipAddressOverride} is not a valid IPv4 address`,
          dismissible: false,
        });
        return;
      }
      createSessionRequest.ipAddressOverride = ipAddressOverride;
      createSessionRequest.ipAddressOverrideReason = ipAddressOverrideReason;
    }
    let bmcConnect = true;
    if ([macAddress, hardwareId, assetId].every(v => v === undefined || v === "")) {
      bmcConnect = false;
    }
    if (bmcConnect) {
      if (macAddress) {
        createSessionRequest.macAddress = macAddress;
      }
      if (hardwareId) {
        createSessionRequest.hardwareId = hardwareId;
      }
      if (assetId) {
        createSessionRequest.assetId = assetId;
      }
    } else {
      if (!availabilityZone) {
        showError({
          content: "Availability Zone is required parameter",
          dismissible: false,
        });
        return;
      }
      if (!fabric) {
        showError({
          content: "Fabric is required parameter",
          dismissible: false,
        });
        return;
      }
    }
    createSessionRequest.availabilityZone = availabilityZone;
    createSessionRequest.fabric = fabric as Fabric;
    const federationAccountEmail = getFederationAccountEmail(availabilityZone, fabric);
    if (isEC2(fabric) && roleName && federationAccountEmail) {
      createSessionRequest.federationRoleName = roleName;
      createSessionRequest.federationAccountEmail = federationAccountEmail;
    }
    const dreamscapeApi: DefaultApi = DreamscapeApiFactory();
    dreamscapeApi.createSession(createSessionRequest).then(resp => {
      if (resp.data.error) {
        showError({
          content: resp.data.error
        });
      } else {
        const session = resp.data.session!;
        navigate({pathname: `/connect/${session.sessionId}`}, {state: location.key});
      }
    }).catch(err => {
      showError({
        content: `Error occurred when creating session: ${err?.response?.data?.error || err}`,
        dismissible: false,
      });
    });
  }, []);

  useEffect(() => {
  }, [flash]);

  return (
    <ContentLayout
      notifications={
        <Flashbar
          items={flash}
        />
      }>
    </ContentLayout>
  );
}
